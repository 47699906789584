import React from "react"
import { graphql, Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const CV = ({ data, location }) => {
    const siteTitle = data.site.siteMetadata.title

    return (
        <Layout location={location} title={siteTitle}>
            <SEO 
              title="Curriculum Vitae" 
              description="Alexander Petkov's Curriculum Vitae"
              pathname="cv"
            />
            <h1 className="display-4 mt-3 mb-5">Curriculum Vitae</h1>
            <h2>Summary</h2>
            <p className="mb-5">
                Since 2008 I have collected working experience in various fields of the 
                software industry (IT, Quality Assurance, Research and Development, Project Management) 
                working for start-ups, medium-sized and international companies.
            </p>

            <h3>Celantur</h3>
            <p><span class="badge badge-primary">Current Position</span> <strong>Founder & CEO</strong>, since May 2019</p>
            <p>
              <Link to="https://www.celantur.com" target="_blank" rel="noopener noreferrer">Celantur</Link> is a start-up specializing in automated image and video data anonymization. 
              It helps businesses to comply with data protection laws by providing technology and services to automatically anonymize images and videos. <br />
              This is done by utilizing state-of-the-art computer vision technology to detect and blur faces, human bodies, license plates, vehicles and many more objects.</p>
            <hr />
            <h3>dataformers</h3>
            <p><strong>Project Manager</strong>, Feb 2018 – Apr 2019, 1 year 3 months</p>
            <p>I have been managing several software projects (IoT, Product Lifecycle Management, HR, Medical, QA) for customers operating on a global scale.</p>
            <hr />
            <h3>Testify</h3>
            <p><strong>Team Lead Software Development</strong>, Jun 2017 - Jan 2018, 8 months</p>
            <p><strong>Software Engineer</strong>, Nov 2016 – Jun 2017, 8 months</p>
            <hr />
            <h3>Borland</h3>
            <p><strong>Research and Development Engineer</strong>, Jun 2009 – Oct 2016, 7 years 5 months</p>
            <p><strong>Lab Technician</strong>, Apr 2008 – May 2009, 1 year 2 months</p>
            <p><strong>Intern</strong>, Aug 2007 – Dec 2007, 5 months</p>
            <hr />
            <h3>Austrian Armed Forces</h3>
            <p><strong>Compulsory Service</strong>, Oct 2008 – Mar 2009, 6 months</p>
            <hr />
            <h3>Siemens</h3>
            <p><strong>Intern</strong>, Jul 2006 – Jul 2006, 1 month</p>
            <hr />
            <h3>VATECH</h3>
            <p><strong>Intern</strong>, Jul 2005 – Jul 2005, 1 month</p>
            
        </Layout>
    )
}

export default CV

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
  }
`
